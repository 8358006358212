import React, { useRef, useState } from 'react';

import { ModuleContainer, CarouselDrag, ModuleHeader, Button } from 'ui';

import { CmsModel } from 'api-types';

import styles from './ModuleCarousel.module.css';

export type ModuleCarouselProps = {
    headline?: string;
    link?: CmsModel.Link;
    items?: React.ReactElement[];
    className?: any;
};

/**
 * Renders items inside a carousel with pagination.
 * Ensures that the carousel is always rendered with the same width.
 *
 */
export const ModuleCarousel = ({
    headline,
    link,
    items = [],
    ...rest
}: ModuleCarouselProps) => {
    const [atEnd] = useState(true);
    const [atBegining] = useState(true);

    const moduleWidthRef = useRef<HTMLDivElement>(null);
    const leftArrowRef = useRef<HTMLButtonElement>(null);
    const rightArrowRef = useRef<HTMLButtonElement>(null);
    return (
        <div>
            <ModuleContainer {...rest} >
                <ModuleHeader headline={headline} link={link} variant='display3'>
                    <div className={styles.pagination}>
                        <Button
                            ref={leftArrowRef}
                            variant='secondary'
                            iconName='arrow-left'
                            iconClassName={styles.svgAlign}
                            title="Scroll left"
                            disabled={atBegining}
                            size='small'
                        />
                        <Button
                            ref={rightArrowRef}
                            title="Scroll right"
                            variant='secondary'
                            iconName='arrow-right'
                            iconClassName={styles.svgAlign}
                            disabled={atEnd}
                            size='small'
                        />
                    </div>
                </ModuleHeader>

                <div ref={moduleWidthRef}>
                    <CarouselDrag
                        leftArrowRef={leftArrowRef}
                        rightArrowRef={rightArrowRef}
                        customScroll={true}
                        hasGutter={false}
                    >
                        <div className={styles.carouselContent}>{items}</div>
                    </CarouselDrag>
                </div>
            </ModuleContainer>
        </div>
    );
};
