import React, { useMemo } from 'react';
import { ModuleCarousel } from '../ModuleCarousel';
import { ModuleContainer } from 'ui';
import { Article } from './components/Article';
import { itemMapper } from './utils/itemMapper';
import { CmsModel } from 'api-types';

export const M80ContentCarousel = ({
    headline,
    callToAction,
    content,
    ...rest
}: CmsModel.M080ContentCarrousel) => {
    const articles = useMemo(() => {
        const items = itemMapper(content);
        return items.map((item, index) => <Article key={index} {...item} />);
    }, [content]);

    return (
        <ModuleContainer hasGutter {...rest}>
            <ModuleCarousel headline={headline} link={callToAction} items={articles} />
        </ModuleContainer>
    );
};
