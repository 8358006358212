import { CmsModel } from 'api-types';

/**
 * Maps reference items to media and text items
 *
 * @param items mixed models of IMediaAndText & IPageReference
 * @returns Array of IMediaAndText
 */
export const itemMapper = (
    items: (CmsModel.MediaAndText | CmsModel.PageReference)[]
): CmsModel.MediaAndText[] => {
    return items.map(
        (item): CmsModel.MediaAndText => {
            return isPageReference(item)
                ? {
                      headline: item.pageTitle,
                      description: `<p>${item.pageDescription}</p>`,
                      image: item.pageImage,
                      callToAction: item.callToAction,
                      type: '',
                  }
                : item;
        }
    );
};

const isPageReference = (
    item: CmsModel.MediaAndText | CmsModel.PageReference
): item is CmsModel.PageReference => {
    return item.type !== 'mediaAndText';
};
